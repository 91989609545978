import { createBrowserRouter, useRouteError } from 'react-router-dom';
import Home from 'src/pages/Home';
import DressUp from 'src/pages/dressUp';
import ErrorPage from 'src/pages/ErrorPage';
import MaintenancePage from 'src/pages/MaintenancePage';
import CardMemory from "src/pages/CardMemory";

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return <ErrorPage />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/dress_up',
    element: <DressUp />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/cards_memory',
    element: <CardMemory />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/merger',
    element: <MaintenancePage />,
    errorElement: <ErrorBoundary />,
  },
]);

export default router;
