import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router';

const Home = () => {
  const navigate = useNavigate();
  const goToDressUp = () => {
    navigate('/dress_up');
  };
  const goToMerger = () => {
    navigate('/merger');
  };
  const goToCrdMemory = () => {
    navigate('/cards_memory');
  };
  return (
    <main className="main-content">
      <div className="game-card" onClick={goToDressUp}>
        <img src="assets/dressUp/dressingThumb.png" alt="DressUp Game" className="game-image" />
        <h2>DressUp Game</h2>
        <p>Show your fashion skills in this creative game!</p>
      </div>
      <div className="game-card" onClick={goToCrdMemory}>
        <img src="assets/cardMemory/thumb.png" alt="Crad Memory Game" className="game-image" />
        <h2>Card Memory Game</h2>
        <p>Test your memory skills in this classic game of matching pairs!</p>
      </div>
      <div className="game-card" onClick={goToMerger}>
        <img src="assets/dressUp/mergerThumb.png" alt="IDLE/Merger Game" className="game-image" />
        <h2>IDLE/Merger Game</h2>
        <p>Combine items to reach new levels in this fun merger game!</p>
      </div>
    </main>
  );
};
export default Home;
